// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";
@import "status";

// If you want to add custom CSS you can put it here.
@import "custom";

.btn-outline-secondary {
  &:focus {
    color: #9da5b1;
  }
  &:hover {
    color: #fff;
  }
}